import React from "react";
import { Link } from "react-router-dom";
import css from "./Breadcrumbs.module.css";
import { useConfiguration } from "../../context/configurationContext";

const Breadcrumbs = ({ listing }) => {
    const config = useConfiguration()
    const categoryConfiguration = config.categoryConfiguration || {};
    const listingCategories = categoryConfiguration.categories;

    const getCategoriesName = (id) => {
        for (let i = 0; i < listingCategories.length; i++) {
            const mainCategory = listingCategories[i];

            if (mainCategory.id === id) {
                return mainCategory['name'];
            }

            // Loop through the subcategories of the current main category
            for (let j = 0; j < mainCategory.subcategories.length; j++) {
                const subCategory = mainCategory.subcategories[j];

                // Check if the subcategory matches the id
                if (subCategory.id === id) {
                    return subCategory['name'];
                }

                // Loop through the sub-subcategories (child categories)
                for (let k = 0; k < subCategory.subcategories.length; k++) {
                    const childCategory = subCategory.subcategories[k];

                    // Check if the child category matches the id
                    if (childCategory.id === id) {
                        return childCategory['name'];
                    }
                }
            }
        }

        return undefined;
    }

    const publicData = listing?.attributes?.publicData || {};

    const categoryLevels = Object.keys(publicData)
        .filter(key => key.startsWith("categoryLevel"))
        .sort((a, b) => a.localeCompare(b));

    const breadcrumbs = categoryLevels.map((level, index) => {
        const pathParams = categoryLevels
            .slice(0, index + 1)
            .map(levelKey => `pub_${levelKey}=${publicData[levelKey]}`)
            .join('&');

        return {
            name: getCategoriesName(publicData[level]),
            path: `/s?${pathParams}`
        };
    });

    return (
        <nav aria-label="breadcrumb">
            <ol className={css.breadcrumb}>
                {breadcrumbs.map((crumb, index) => (
                    <li key={index} className={css.breadcrumbItem}>
                        {crumb.path ? <Link to={crumb.path}>{crumb.name}</Link> : crumb.name}
                        {index < breadcrumbs.length - 1 && <span className={css.separator}>/</span>}
                    </li>
                ))}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
